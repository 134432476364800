import styled from "styled-components";
import construction from '../src/img/Construction.png'

export const WebContainer = styled.div`
top:0;
left: 0;
right: 0;
bottom: 0;
position: absolute;
display: flex;
flex-direction: column;
justify-content: center;
background-color: #45b9e0;
align-items: center;
align-content: center;
`



export const OtterLogo = styled.div`
height: 500px;
width: 800px;
background-color: transparent;
display: flex;
justify-content: center;
`

export const OtterImage = styled.img`
height: 500px;
width: 500px;
`

export const OtterText = styled.div`
height: 200px;
width: 800px;
align-content: center;
background-color: transparent;
align-items: center;
display: flex;
flex-direction: column;
padding: 60px;
color: white;
text-align: center;
line-height: 2;
`