import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import styled from 'styled-components'
import { OtterImage, OtterLogo, OtterText, WebContainer } from './AppElements';
import Construction from '../src/img/Construction.png'

function App() {



  return (
    <WebContainer>
      <OtterLogo>
        <OtterImage src={Construction}/>
      </OtterLogo>
      <OtterText>
        <h1>Sorry! Site under construction.</h1>
        <h1>**COMING SOON**</h1>
      </OtterText>
    </WebContainer> 
  );


}

export default App;
